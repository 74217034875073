<template>
    <div class="wrapper">
        <Header :code="positionViewForm.projectCode"></Header>
        <div class="page-title icon"><Icon type="md-sad" /></div>
        <div class="content">
            <div class="main-content">

                <div class="prompt">
                    <div class="headline">温馨提示：</div>
                    <div class="section" v-if="positionViewForm.auditResource==='未上传' && positionViewForm.statusName !=='待资审'">
                        抱歉！您的报名还未到提交资质环节，请耐心等待通知！
                    </div>
                    <div class="section" v-else >
                        提交资质链接错误，请核实后重新打开！
                    </div>
                </div>
                
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "../../components/pc/Header";
import Footer from "../../components/pc/Footer"
import {getPositionById} from "../../api/client-api";

export default {
    components: {
        Header: Header,
        Footer: Footer
    },
    data() {
        return {
            positionViewForm: {},
            viewForm: {},
            positionId: null,
            status: '',
        }
    },
    methods: {
        getPosition() {
            getPositionById({id: this.positionId}).then((res)=>{
                if (res.data.data) {
                    this.positionViewForm = res.data.data
                }
            })
        },
    },
    mounted() {
        this.positionId = this.$route.query.id;
        this.getPosition()
    }
};
</script>

<style scoped>

</style>
