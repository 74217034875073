<template>
  <div class="wrapper has_nav_bar">
      <van-nav-bar title="温馨提示" />
      <div class="content">
          <div class="main-content">
            <div class="prompt">
              <div class="section">
                <van-icon name="warning-o" />
                <p v-if="positionViewForm.auditResource==='未上传' && positionViewForm.statusName !=='待资审'">抱歉！您的报名还未到提交资质环节，请耐心等待通知！</p>

                <p v-else>提交资质链接错误，请核实后重新打开！</p>
              </div>
            </div>
          </div>
      </div>
      <div class="footer">
      </div>
      <van-tabbar>
          <van-tabbar-item name="home" icon="wap-home">招聘主页</van-tabbar-item>
          <van-tabbar-item :badge='messageCounts' name="message" icon="chat" @click="goMessageCenter">消息中心</van-tabbar-item>
          <van-tabbar-item name="application" icon="column" @click="goMyApplication">我的报名</van-tabbar-item>
          <van-tabbar-item name="my" icon="manager" @click="goUserCenter">个人中心</van-tabbar-item>
      </van-tabbar>
  </div>
</template>

<script>
import {getCountOfUnreadMessage, getPositionById} from "../../api/client-api";

export default {
  data() {
      return {
          messageCounts: "",
          positionViewForm: {},
          positionId: null,
      }
  },
  methods: {
      getPosition() {
          getPositionById({id: this.positionId}).then((res)=>{
              if (res.data.data) {
                  this.positionViewForm = res.data.data
              }
          })
      },
      goMessageCenter() {
          this.$router.push({name: 'messageCenter', query: {projectCode:  this.positionViewForm.projectCode}});
      },
      goMyApplication() {
          this.$router.push({name: 'myApplication', query: {projectCode:  this.positionViewForm.projectCode}});
      },
      goUserCenter() {
          this.$router.push({name: 'userCenter', query: {projectCode: this.positionViewForm.projectCode}});
      },
      countOfUnreadMessage() {
          getCountOfUnreadMessage().then((res) => {
              if (res.data.data !== 0){
                  this.messageCounts = res.data.data + '';
              }
          }).catch((err)=>{
              this.messageCounts = null;
          });
      },
  },
    mounted() {
        let that = this;
        that.isLogon = sessionStorage.getItem('Authorization') ? true : false;
        if (that.isLogon) {
            that.countOfUnreadMessage();
            that._timer = setInterval(function() {
                that.countOfUnreadMessage();
            }, 1000*60);
        }
        this.positionId = this.$route.query.id;
        this.getPosition()
  }
};
</script>

<style scoped>

</style>
